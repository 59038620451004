import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "header-action-scroll"
};
const _hoisted_2 = {
  class: "header-action-wrap"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "action-btn-title download"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "action-btn-title"
};
const _hoisted_7 = {
  key: 0,
  class: "badge-wrap"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "action-btn-title invite-spin"
};
const _hoisted_10 = {
  key: 0,
  class: "badge-wrap"
};
const _hoisted_11 = ["src"];
const _hoisted_12 = {
  class: "action-btn-title milestone"
};
const _hoisted_13 = {
  key: 0,
  class: "badge-wrap"
};
const _hoisted_14 = ["src"];
const _hoisted_15 = {
  class: "action-btn-title inbox"
};
const _hoisted_16 = {
  key: 0,
  class: "badge-wrap"
};
import { ref, onMounted, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import ws from '@/connect';
import { useHelpTool, usePlatform } from "@/hooks";
import { usePopupStore, useSettingsStore, useReddotStore } from '@/store';
export default {
  __name: 'HomeTopMenu',
  setup(__props) {
    const {
      openBrowser
    } = useHelpTool();
    const Platform = usePlatform();
    const popupStore = usePopupStore();
    const settingsStore = useSettingsStore();
    const {
      downloadPage
    } = storeToRefs(settingsStore);
    const redDotStore = useReddotStore();
    const {
      loginBonusRewardNum,
      inviteFriendToEarnNum,
      inboxUnread,
      taskRewardNum
    } = storeToRefs(redDotStore);
    const showDownload = ref(false);
    onMounted(() => {
      showDownload.value = window.$bridge || Platform.isIOS ? false : true;
      ws.registerCallBack('inbox.unread', data => {
        redDotStore.set('inboxUnread', data);
      });
      ws.registerCallBack('invite.friends.spins', data => {
        redDotStore.set('inviteFriendToEarnNum', data);
      });
      ws.registerCallBack('task.reward', data => {
        redDotStore.set('taskRewardNum', data);
      });
      ws.registerCallBack('badge.sign.up', data => {
        redDotStore.set('loginBonusRewardNum', data);
      });
    });
    onUnmounted(() => {
      if (ws) {
        ws.unregisterCallBack('inbox.unread');
        ws.unregisterCallBack('invite.friends.spins');
        ws.unregisterCallBack('task.reward');
        ws.unregisterCallBack('badge.sign.up');
      }
    });
    return (_ctx, _cache) => {
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [showDownload.value ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "menu-action-btn",
        onClick: _cache[0] || (_cache[0] = $event => _unref(openBrowser)(_unref(downloadPage)))
      }, [_createElementVNode("img", {
        class: "action-btn-icon",
        src: require('@/assets/img/action-btn/download.png')
      }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('actionBtn.getReward').toUpperCase()), 1)])), [[_directive_audio]]) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "menu-action-btn",
        onClick: _cache[1] || (_cache[1] = $event => _unref(popupStore).set('showDaliyBonus'))
      }, [_createElementVNode("img", {
        class: "action-btn-icon",
        src: require('@/assets/gif/action-btn/daily.gif')
      }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('actionBtn.daily').toUpperCase()), 1), _unref(loginBonusRewardNum) ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(loginBonusRewardNum)), 1)) : _createCommentVNode("", true)])), [[_directive_audio]]), _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "menu-action-btn long",
        onClick: _cache[2] || (_cache[2] = $event => _unref(popupStore).set('showLuckyWheel'))
      }, [_createElementVNode("img", {
        class: "action-btn-icon",
        src: require('@/assets/gif/action-btn/invite-spin.gif')
      }, null, 8, _hoisted_8), _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('actionBtn.invite').toUpperCase()), 1), _unref(inviteFriendToEarnNum) ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(inviteFriendToEarnNum)), 1)) : _createCommentVNode("", true)])), [[_directive_audio]]), _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "menu-action-btn",
        onClick: _cache[3] || (_cache[3] = $event => _unref(popupStore).set('showTask'))
      }, [_createElementVNode("img", {
        class: "action-btn-icon",
        src: require('@/assets/gif/action-btn/task.gif')
      }, null, 8, _hoisted_11), _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('actionBtn.task').toUpperCase()), 1), _unref(taskRewardNum) ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_unref(taskRewardNum)), 1)) : _createCommentVNode("", true)])), [[_directive_audio]]), _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "menu-action-btn",
        onClick: _cache[4] || (_cache[4] = $event => _unref(popupStore).set('showInbox'))
      }, [_createElementVNode("img", {
        class: "action-btn-icon",
        src: require('@/assets/gif/action-btn/inbox.gif')
      }, null, 8, _hoisted_14), _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('actionBtn.inbox').toUpperCase()), 1), _unref(inboxUnread) ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_unref(inboxUnread)), 1)) : _createCommentVNode("", true)])), [[_directive_audio]])])]);
    };
  }
};