import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "right-wrap"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "action-btn-title follow"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "action-btn-title redeem"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "action-btn-title security-center"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "action-btn-title bind"
};
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { usePopupStore, useUserStore } from '@/store';
export default {
  __name: 'HomeRightMenu',
  setup(__props) {
    const popupStore = usePopupStore();
    const userStore = useUserStore();
    const {
      guest
    } = storeToRefs(userStore);
    return (_ctx, _cache) => {
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives((_openBlock(), _createElementBlock("div", {
        class: "menu-action-btn",
        onClick: _cache[0] || (_cache[0] = $event => _unref(popupStore).set('showSocialMedia'))
      }, [_createElementVNode("img", {
        class: "action-btn-icon",
        src: require('@/assets/gif/action-btn/follow.gif')
      }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('actionBtn.follow').toUpperCase()), 1)])), [[_directive_audio]]), _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "menu-action-btn",
        onClick: _cache[1] || (_cache[1] = $event => _unref(popupStore).set('showRedeem'))
      }, [_createElementVNode("img", {
        class: "action-btn-icon",
        src: require('@/assets/gif/action-btn/redeem.gif')
      }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('actionBtn.redeem').toUpperCase()), 1)])), [[_directive_audio]]), _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "menu-action-btn",
        onClick: _cache[2] || (_cache[2] = $event => _unref(popupStore).set('showSecurityCenter'))
      }, [_createElementVNode("img", {
        class: "action-btn-icon",
        src: require('@/assets/img/action-btn/security-center.png')
      }, null, 8, _hoisted_6), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('actionBtn.securityCenter').toUpperCase()), 1)])), [[_directive_audio]]), _unref(guest) ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "menu-action-btn",
        onClick: _cache[3] || (_cache[3] = $event => _unref(popupStore).set('showBindAccount'))
      }, [_createElementVNode("img", {
        class: "action-btn-icon",
        src: require('@/assets/img/action-btn/bind.png')
      }, null, 8, _hoisted_8), _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('actionBtn.bind').toUpperCase()), 1)])), [[_directive_audio]]) : _createCommentVNode("", true)]);
    };
  }
};