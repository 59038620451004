import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-12c70d1b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "avatar-wrap"
};
const _hoisted_2 = {
  class: "user-vip"
};
const _hoisted_3 = {
  class: "user-name"
};
import { storeToRefs } from 'pinia';
import { usePopupStore, useUserStore } from '@/store';
export default {
  __name: 'UserProfile',
  setup(__props) {
    const popupStore = usePopupStore();
    const userStore = useUserStore();
    const {
      avatar,
      username,
      nickname,
      vip
    } = storeToRefs(userStore);
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _directive_audio = _resolveDirective("audio");
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "user-profile-wrap",
        onClick: _cache[0] || (_cache[0] = $event => _unref(popupStore).set('showProfile'))
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_image, {
        class: "user-avatar",
        fit: "contain",
        src: _unref(avatar) || require('@/assets/img/user/avatar-default.png')
      }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('vip.vip').toUpperCase()) + " " + _toDisplayString(_unref(vip)), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(nickname) || _unref(username)), 1)])), [[_directive_audio]]);
    };
  }
};